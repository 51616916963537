<template>
  <div class="input-group" :class="{ 'input--error': v && v.$error }">
    <label v-if="label" :for="slugify(label)">
      {{ label }}
      <span v-if="required" class="required"> *</span>
    </label>
    <input
      :id="slugify(label as string)"
      v-bind="$attrs"
      v-model="handleInput"
      :class="['input', errorClass]"
      :data-testid="`${formatNameForTestid(name)}_input`"
      :name="name"
      :autocomplete="name"
      @blur="v.$touch()"
    />
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'
import { slugify } from '@/utils/helpers'
import { formatNameForTestid } from '@/utils/forms'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  modelValue: string | number
  label?: string
  required?: boolean
  v?: Validation['ExtractRulesResults']
  name?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string | number): void
}>()
const { handleInput, errorMessage, errorClass, showErrorMessage } = useInput(
  props,
  emit,
)
</script>
<style lang="scss" scoped>
.input {
  width: 100%;
}
</style>
